.mainContainer {
  display: flex;
  flex-direction: column;
}

.whoWillBeInTheChatContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  padding: 2rem;
}

.membersContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--ion-color-light-tint);
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.membersBubblesContainer {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
}

.groupChatButton {
  margin-top: 1rem;
}

.privateChatButton {
  margin-top: 1rem;
}
