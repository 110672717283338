.helpItem {
  --detail-icon-color: var(--ion-color-medium);
  --detail-icon-font-size: 0.9rem;
  --detail-icon-opacity: 1;
  --padding-start: 0.6rem;
  --padding-top: 0.3rem;
  --padding-bottom: 0.3rem;

  cursor: pointer;

  ion-icon {
    padding: 0.4rem;
    font-size: 1.2rem;
    background: var(--ion-color-light-tint);
    margin-right: 0.5rem;
  }

  ion-label {
    font-size: 1rem;
    font-weight: 400;
  }
}

.link {
  text-decoration: none;
}
