.searchbar {
  --background: var(--ion-item-background);
  --border-radius: 0.3em;

  margin-top: 0.3rem;
  padding: 0;
}

.searchLabel {
  font-size: 1em;
  margin-bottom: 0.5em;
}

.listContainer {
  overflow-y: scroll;
  max-height: 50vh;
  margin-top: 1rem;
  padding-bottom: 7rem; // gives last list item breathing room for very small viewport

  .ionItemContainer {
    margin-bottom: 1rem;
  }

  .itemDetails {
    width: 100%;
  }
}

.searchSpinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  .searchSpinner {
    margin: 0.2em;
  }
}

.companyHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h4 {
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
  }

  .activeStatus {
    color: var(--ion-color-success);
    align-self: center;
  }

  .companyIconTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;

    ion-icon {
      margin-right: 0.8em;
    }
  }
}

.childItem {
  width: 100%;
  border-radius: 0.4rem;
  word-break: break-word;

  --padding-top: 1em;
  --padding-bottom: 1em;
  --box-shadow: var(--theme-custom-box-shadow);
}

.inactiveStatus {
  --background: rgb(0 0 0 / 5%);
}

.selected {
  border: 0.15rem solid var(--ion-color-primary);
  font-weight: 500;
}

.noDataFoundContainer {
  ion-button {
    --background: none;
    --box-shadow: none;
    --padding-start: 0;
    --background-hover: none;

    text-transform: none;

    ion-label {
      display: flex;
      flex-direction: column;
      text-align: start;
      text-transform: none;
    }
  }

  a {
    margin-top: 0.2em;
    color: var(--ion-color-primary);
    text-decoration: underline;
  }
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
  margin: 1em 0 0;
  text-align: end;
}
