.acceptRejectButtons {
  ion-button {
    margin: 1rem;

    ion-spinner {
      transform: scale(50%);
    }
  }
}

.descriptionCopy {
  padding: 0.3rem 0;
  font-size: 1rem;
}

.tenderReadinessTitle {
  margin-bottom: 0;
}

.tenderReadinessText {
  padding-top: 0;

  ion-icon {
    margin-bottom: -0.13rem;
  }
}

.map {
  height: 10rem;
  width: 100%;

  &::after {
    $circle-size: 7rem;

    content: "";
    border: 0.3rem solid var(--ion-color-dark-tint);
    border-radius: $circle-size;
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card {
  ion-item {
    --border-style: none;
  }
}

.titleLabel {
  font-size: 1rem;
}

.row {
  width: 50%;
}

// remove me when you remove the flag

.actionButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1rem 0;

  ion-button {
    width: 8rem;

    ion-spinner {
      transform: scale(50%);
    }
  }
}

.projectFull {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-dark-contrast);
  height: 2rem;
  padding-top: 0.4rem;
  font-size: 1rem;
  font-weight: 700;
}

.blurred {
  filter: blur(0.25rem);
}

.outlineButton {
  margin-top: 0.5rem;

  --border-width: 0.08rem;
}

.fomoContractors {
  ion-icon {
    padding-left: 0;
  }
}

.countDownTextColor {
  margin-left: 0.2rem;
  color: var(--ion-color-danger);
}
