@media only screen and (min-width: 768px) {
  .responsiveContentWrapper {
    max-width: 50vw;
    margin: 0 auto;
  }
}

.fullHeight {
  height: 100%;
}
