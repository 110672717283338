.childItem {
  width: 100%;
  border-radius: 0.4rem;
  border: 0.05rem solid var(--ion-color-medium);
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.selected {
  border: 0.15rem solid var(--ion-color-primary);
  font-weight: 500;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
