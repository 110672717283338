.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    font-weight: bold;
    color: var(--ion-color-light);
  }
}
