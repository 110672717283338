.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.body {
  text-align: center;
}

.container p {
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--ion-color-medium);
  margin: 0;
}

.container a {
  text-decoration: none;
}
