.devAccountOnly {
  padding: 1em;
  background-color: var(--ion-color-primary);
}

.devAccountOnlyHeading {
  padding-bottom: 1em;
  color: var(--theme-white);
  font-weight: bolder;
}
