.inputContainer {
  margin-bottom: 1em;

  .errorMessage {
    margin: 0;
    text-align: end;
    color: var(--ion-color-danger);
    font-size: 0.8rem;
  }
}
