.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}

.addPastProjectButton {
  font-size: 1.2rem;
  text-transform: none;
}

.pastProjectHeaderContainer {
  height: 25%;
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 7em;
    margin-top: 0.5em;
  }

  h5 {
    font-weight: bold;
    font-size: 1.5em;
    color: var(--ion-item-background);
  }
}

.badgeContainer {
  display: flex;
  justify-content: space-evenly;

  p {
    font-weight: bold;
  }

  .badge {
    cursor: pointer;
    height: 5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .pastProjectHeaderContainer {
    min-width: 50%;
    margin: 0 auto;
  }

  .badgeContainer {
    width: 75%;
  }

  .indicatorContainer {
    min-width: 50%;
    margin: 0 auto;
  }
}
