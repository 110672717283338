.memberItem {
  p {
    font-size: 1rem;
    color: var(--ion-text-color);
  }

  .memberLabel {
    cursor: pointer;
  }

  ion-label:last-child {
    margin-top: -0.2rem;

    p {
      font-size: 0.75rem;
      color: var(--ion-color-medium);
    }
  }
}

.chatIconButton {
  border: 0;
  margin: 0;
}

.chatChipButton {
  --color: var(--ion-color-primary);

  border-color: var(--ion-color-primary);

  .chipText {
    font-size: 1rem;

    --color: var(--ion-color-primary);

    padding-bottom: 0.19rem;
  }
}

.popOverItem {
  cursor: pointer;
  background-color: var(--ion-item-background);
}

.popOverItem:hover {
  background-color: var(--ion-color-light-tint);
}

.menuIcon {
  cursor: pointer;
}
