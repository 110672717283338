.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--ion-item-background);
  margin-top: 1rem;
  border-radius: 0.25rem;
  border: 0.025rem dashed var(--ion-color-medium);
  flex: 1;
}

.uploadDocumentsImage {
  margin-top: 3rem;
  height: 5rem;
  width: 5rem;
}

.uploadYourDocumentsText {
  font-weight: bold;
}

.supportedFormatsText {
  color: var(--ion-color-medium);
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.uploadYourDocumentsButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.filesToUploadContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
}

.confirmButton {
  text-transform: uppercase;
}
