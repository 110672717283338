.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}

.errorMessage {
  text-align: end;
  margin: 0 0 0.2rem;
  font-size: 0.8rem;
  color: var(--ion-color-danger);
}

.pastProjectHeaderContainer {
  height: 25%;
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 7em;
    margin-top: 1.5em;
  }

  h5 {
    font-weight: bold;
    font-size: 1.5em;
    color: var(--ion-item-background);
  }
}

.constructionOngoingCheckBox {
  --background: none;
}
