.container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.25rem;
  margin: 0;
  height: 100%;
  width: 100%;
}

.contentHeader {
  margin-bottom: 0.5em;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.label {
  font-size: 1rem;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  max-width: 15rem;
}

.profileImage {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.5rem;
  color: var(--ion-color-medium);
}

.subtext {
  font-size: 0.7rem;
}
