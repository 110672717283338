@use "sass:math";

$transition-time: 0.2s;
$item-size: 1.8rem;
$line-height: 0.2rem;

.indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: math.div($item-size, 2) $item-size;
  min-height: $item-size * 2;

  > .indicatorItem {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: - math.div($line-height, 2);
      height: $line-height;
      width: 100%;
      background-color: var(--ion-color-light-shade);
      transition: $transition-time ease-in background-color;
    }

    // icon element
    > div {
      width: $item-size;
      height: $item-size;
      border-radius: $item-size;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--ion-color-light-shade);
      position: relative;
      transition: $transition-time ease-in background-color;

      ion-icon {
        color: var(--ion-item-background);
        font-size: $item-size - 0.2rem;
      }
    }

    &.done,
    &.active {
      &::before {
        background-color: var(--ion-color-primary);
      }

      > div {
        background-color: var(--ion-color-primary);
      }
    }

    &.unavailable {
      &::before {
        background-color: var(--ion-color-light);
      }

      > div {
        background-color: var(--ion-color-light);
      }
    }

    &:first-child {
      // hide the progress bar (::before) on the first item by
      // forcing the first child to be the same width as it's item
      width: $item-size;

      &::before {
        display: none;
      }
    }
  }
}
