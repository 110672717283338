.chatRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 4.5rem;
  padding-left: 1rem;
}

.chatRow ion-item {
  width: 100%;
}

.chatRowAvatar {
  height: 3rem;
  width: 3rem;
}

.chatBottomContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.unreadMessagesCircle {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  padding-top: 0.15rem;
  margin-bottom: 0.1rem;
  margin-right: 1.5rem;
  font-size: 0.65rem;
  font-weight: normal;
}

.chatLastMessagePreview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chatContentContainer {
  --background: var(--weaver-background-color);
}

.chatTopContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  align-items: space-between;
}

.chatName {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatDate {
  font-size: 0.8rem;
  width: 5rem;
  padding-right: 0.5rem;
}

.chatDate::first-letter {
  text-transform: capitalize;
}

// New Stuff

.newChatRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 4.5rem;
  padding-left: 0.8rem;
  background-color: var(--ion-item-background);
  border-bottom: 0.15rem solid var(--ion-color-light);
}

.newChatRow ion-item {
  width: 100%;
}

.newChatRowAvatar {
  height: 3rem;
  width: 3rem;
}

.newChatBottomContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;

  .newChatLastMessagePreview {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 3.5rem;
    text-overflow: ellipsis;
  }
}

.newUnreadMessagesCircle {
  border-radius: 50%;
  margin-bottom: 0.1rem;
  width: 1.35rem;
  min-width: 1.35rem;
  height: 1.35rem;
  padding-top: 0.15rem;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  text-align: center;
  font-size: 0.75rem;
}

.newChatContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.newChatTopContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  align-items: space-between;
  margin-top: 0.6rem;

  .newChatName {
    font-size: 1.1rem;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.newChatDate {
  font-size: 0.9rem;
  white-space: nowrap;
}

.newChatDateForChatWithUnreadmessages {
  color: var(--ion-color-primary);
}

.newChatDate::first-letter {
  text-transform: capitalize;
}

.circleForIcon {
  display: flex;
  height: 3rem;
  width: 3.3rem;
  background-color: var(--ion-background-color);
  border-radius: 50%;
  align-items: flex-start;

  ion-icon {
    color: var(--ion-color-medium);
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}
