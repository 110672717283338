.card {
  ion-item {
    --border-style: none;
  }
}

.titleLabel {
  font-size: 1rem;
}

.headerColumn {
  width: 30%;
}

.contentColumn {
  width: 70%;
}
