.companyInfoContainer {
  background-color: var(--ion-color-medium-contrast);
  padding: 0 1rem;
  border-radius: 0 0 0.3rem 0.3rem;

  p {
    color: var(--ion-color-light-grey);
    line-height: 1.3;
  }

  svg {
    margin-top: 0.5em;
    margin-right: 1em;
  }

  ion-icon {
    color: var(--theme-light-blue);
    height: 0.8em;
  }

  .companyTradingName {
    margin-bottom: 0;
    max-width: 70%;
  }

  .contractorTeamType {
    margin-bottom: 0.25rem;
  }

  .registeredOfficeTitle {
    font-size: 0.9rem;
    margin: 0;
  }

  .registeredOfficeTitleAddress {
    font-size: 0.8rem;
  }

  .mapSection {
    padding: 0;
    margin-top: 0.5rem;
  }
}

.weaverStatsContainer {
  padding: 0;

  .statRowContainer {
    display: flex;
    margin-bottom: 0.7rem;

    .statIcon {
      font-size: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  .tieredBadgeRangeContainer {
    background-color: var(--ion-item-background);

    .badgeTitle {
      margin: 0;
    }
  }
}

.statRowContainer:last-child {
  margin-bottom: 0;
  padding-bottom: 1rem;
}
