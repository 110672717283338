.customIonImg {
  height: 15rem;
}

.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}
