.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}

.onboardingFinishScreen {
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}
