.childItem {
  flex-grow: 1;
  border-radius: var(--theme-rounding);
  margin-bottom: 0.5em;

  --inner-padding-bottom: 0.5em;
  --inner-padding-top: 0.5em;
}

.label {
  flex-grow: 1;
  padding-left: 1em;
}

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  .itemContainer:not(:first-of-type) {
    min-width: 85%;
    margin-left: auto;
    position: relative;
  }
}

.itemContainer:not(:first-of-type)::after {
  content: " ";
  display: block;
  width: 0.125em;
  height: 165%;
  position: absolute;
  top: -15%;
  bottom: 0;
  left: -1.5em;
  border-left: 1px solid var(--ion-color-medium-tint);
  background-color: var(--ion-color-medium-tint);
}

.itemContainer:not(:first-of-type)::before {
  content: " ";
  display: block;
  width: 1.5em;
  height: 0.125em;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: -1.5em;
  border-radius: var(--theme-rounding);
  background-color: var(--ion-color-medium-tint);
}

.itemContainer:last-child::after {
  height: 0;
}
