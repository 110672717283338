.customToast {
  position: absolute;
  bottom: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: bottom 200ms ease-in-out;

  &.chatroomPadding {
    bottom: 12rem;
  }

  ion-item {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
    --height: 2rem;
    --border-radius: 1.1rem;
    --padding-start: 0;
    --inner-padding-top: 0;

    padding-inline-start: 0;
    border-radius: 1.1rem;
    padding-top: 0;
    box-shadow: rgb(0 0 0 / 32%) 0 0.25rem 1rem;
    opacity: 1;
    max-width: 48rem;
    min-width: 24rem;

    &.enter {
      animation: toastEnter 400ms cubic-bezier(0.38, 0, 0.05, 1.29);
    }

    &.exit {
      animation: toastExit 250ms cubic-bezier(1, -0.33, 0.71, 0.99);
    }

    .imgContainer {
      margin-inline: 0;
      margin: 0;
    }

    .header {
      font-size: 0.9rem;
    }

    .message {
      font-weight: 550;
    }
  }

  img {
    height: 6rem;
    width: 100%;
  }
}

@keyframes toastEnter {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0) scale(90%);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes toastExit {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0) scale(90%);
  }
}
