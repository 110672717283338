.faqContainer {
  --background-color: var(--ion-item-background);

  color: var(--ion-text-color);
  border-radius: none;

  ion-accordion::part(content) {
    border-bottom: none;
    background: var(--ion-item-background);
  }

  ion-accordion::part(content expanded) {
    margin-bottom: 0;
    background: var(--ion-item-background);
  }

  ion-accordion::part(content expanded):last-child {
    background: var(--ion-item-background);
  }
}
