.downloadUploadedFileModal {
  &::part(content) {
    width: 20rem;
    height: 4rem;
  }
}

.container {
  display: flex;
  height: 100%;
  margin-left: 0.75rem;
  cursor: pointer;
  align-items: center;

  .statusDescription {
    margin-left: 0.75rem;
  }

  .downloadButton {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .downloadSpinner {
    font-size: 1.3rem;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    max-width: 1rem;
    max-height: 1.3rem;
  }
}

.error {
  margin-left: 0;
}
