.fullSizeImageModal {
  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 20rem;
    height: 4rem;
  }
}

.container {
  display: flex;
  height: 100%;
  margin-left: 0.75rem;
  cursor: pointer;
  align-items: center;

  .statusDescription {
    margin-left: 0.75rem;
    color: var(--ion-color-primary);
  }

  .downloadIcon {
    margin-left: 0.75rem;
    margin-top: 0.05rem;
  }
}

.error {
  margin-left: 0;
  align-items: center;
  justify-content: center;
}
