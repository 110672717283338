.buttonIcon {
  margin-right: 0.5rem;
}

.ionItem {
  --ion-item-background: var(--ion-color-medium-contrast);
}

.cursorEnabled {
  cursor: pointer;
}

.displayContractorProfileContainer {
  p {
    margin: 0;
  }

  ion-list {
    margin-top: 0;
    padding-top: 0;
  }

  .companyInfoContainer {
    background-color: var(--ion-color-medium-contrast);
    padding: 0 1rem;
    border-radius: 0 0 0.3rem 0.3rem;

    p {
      color: var(--ion-color-light-grey);
      line-height: 1.3;
    }

    svg {
      margin-top: 0.5em;
      margin-right: 1em;
    }

    ion-icon {
      color: var(--theme-light-blue);
      height: 0.8em;
      margin-left: 0.5em;
    }

    .companyTradingName {
      margin-bottom: 0;
      max-width: 70%;
    }

    .contractorTeamType {
      margin-bottom: 0.25rem;
    }
  }

  .tieredBadgeRangeContainer {
    background-color: var(--ion-item-background);

    .badgeTitle {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
}

.unlockedBudgetRangesContainer {
  display: flex;
  flex-direction: column;

  ion-button {
    margin-top: 1em;
    max-width: 18em;
  }
}

.unlockedBudgetRangesButton {
  display: flex;
  flex-direction: column;
  background-color: var(--ion-item-background);

  ion-button {
    max-width: 18em;
  }
}

.listWorkHistoryContainer {
  background-color: var(--ion-item-background);
  padding-top: 1rem;

  .pastProjectsTitle {
    margin: 0;
  }
}

.profileDetailsContainer {
  .profileDetails {
    border: 0.188rem solid var(--ion-color-tertiary);
    background-color: var(--ion-color-medium-contrast);
    border-radius: 0.7rem;
    padding: 0 0.5rem 0.5rem 1rem;

    ion-chip {
      color: var(--ion-color-tertiary);
      font-weight: 600;
      background-color: var(--ion-color-tertiary-tint);
      margin-bottom: -1rem;
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
      height: 1.2rem;
      font-size: 0.75rem;
      margin-inline: 0;
    }

    .eyeContainer {
      color: var(--ion-color-medium);
      margin-top: -0.6rem;

      ion-icon {
        margin-bottom: -0.13rem;
      }
    }

    .profileStatistics {
      border: 0.01rem solid var(--ion-color-light-shade);
      border-radius: 0.9rem;
      margin: 0.7rem 0;
      padding: 1rem 0.6rem;

      ion-icon {
        color: var(--ion-color-success);
      }

      .title {
        font-weight: 520;
        margin-bottom: 0.3rem;
      }

      .subTitle {
        color: var(--ion-color-medium-shade);
      }
    }

    ion-button {
      --border-width: 0.08rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .displayContractorProfileContainer {
    .unlockedBudgetRangesContainer,
    .workHistoryListContainer,
    .tieredBadgeRangeContainer,
    .profileDetailsContainer,
    .teamMemberListContainer {
      padding: 0 1em 0.5em;
    }
  }
}
