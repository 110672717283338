.rowIonItem {
  height: 2.5rem;

  --padding-end: 0;
  --inner-padding-end: 0;
}

.refreshIcon {
  font-size: 1.4rem;
  margin-inline: 0;
}

.taskIcon {
  font-size: 1.8rem;
  color: var(--ion-color-medium);
}

.ionLabel {
  font-weight: 400;
  font-size: 1rem;
}

ion-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.subtextContainer {
  display: flex;
  color: var(--ion-color-medium);
  flex-wrap: wrap;
  gap: 0;

  div {
    display: flex;
    gap: 0.1rem;
    margin-right: 0.2rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}

.taskItem {
  --detail-icon-color: var(--ion-color-medium);
  --detail-icon-font-size: 0.9rem;
  --detail-icon-opacity: 1;
  --padding-start: 0.6rem;
  --padding-top: 0.3rem;
  --padding-bottom: 0.3rem;

  ion-icon {
    padding: 0.4rem;
    font-size: 1.2rem;
  }

  .itemContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    ion-label {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .iconContainer {
    margin-right: 1rem;
  }

  .statusContainer {
    display: flex;
    align-items: flex-end;
    color: var(--ion-color-medium);
    gap: 0.4rem;
    white-space: nowrap;
    flex: column nowrap;
    flex-direction: column;

    div {
      display: flex;
      gap: 0.2rem;
      justify-content: flex-end;
    }

    ion-icon {
      font-size: 1.3rem;
    }
  }

  &.actionable {
    cursor: pointer;
  }

  &.notStarted {
    .iconContainer {
      ion-icon {
        /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
        background-color: rgb(var(--ion-color-primary-rgb), 0.1);
        color: var(--ion-color-primary);
      }
    }
  }

  &.locked {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-light);
        color: var(--ion-color-medium);
      }
    }

    .itemContentContainer {
      opacity: 0.65;

      ion-label {
        font-weight: 400;
        font-size: 1rem;
        color: var(--ion-color-text);
      }

      .subtextContainer {
        color: var(--ion-color-medium);
      }
    }
  }

  &.nextStep {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
      }
    }
  }

  &.skipped {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-warning-tint);
        color: var(--ion-color-medium);
      }
    }
  }

  &.completed {
    .iconContainer {
      /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
      background-color: rgb(var(--ion-color-primary-rgb), 0.1);
      padding: 0.4rem;

      ion-icon {
        padding: 0.125rem;
        font-size: 0.9rem;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        border-radius: 0.25rem;
      }
    }
  }

  &.unactionable {
    opacity: 0.5;
  }
}

.ionRefresher {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}
