.overallContainer {
  margin: auto;
  margin-bottom: 6rem;
  height: 100%;
}

.costOfWorkContainer {
  padding-top: 0.5em;

  span {
    p {
      color: var(--ion-color-medium);
      font-size: 1.2em;
      margin-top: 0;
    }

    p:nth-child(1) {
      margin-bottom: 0;
    }

    p:nth-child(2) {
      margin-top: 0.2em;
    }
  }
}

.typeOfWorkContainer {
  --inner-padding-bottom: 1em;

  color: var(--ion-color-medium);
  font-size: 1.2em;
  margin-bottom: 1em;

  ion-badge {
    margin-right: 0.5em;
  }
}

.photoContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 10em);
  grid-template-rows: auto;

  .photoItemContainer {
    img {
      object-fit: contain;
      height: 100%;
    }
  }
}

.gridRowGap {
  grid-row-gap: 1rem;
}

.ionContent {
  --ion-background-color: var(--ion-color-light-tint);

  h3 {
    margin-top: 0;
  }

  ion-item {
    max-width: 80%;

    --ion-item-background: var(--ion-color-light-tint);
    --inner-padding-start: 0;
  }

  ion-icon {
    color: var(--ion-color-medium);
    margin-right: 1em;
    margin-left: -0.5em;
  }
}
