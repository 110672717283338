/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

@font-face {
  font-family: "Rebrand Text";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Rebrand/static/RebrandTxt-Regular.otf");
}

@font-face {
  font-family: "Rebrand Text";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/fonts/Rebrand/static/RebrandTxt-Regularit.otf");
}

@font-face {
  font-family: "Rebrand Text";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/Rebrand/static/RebrandTxt-Bold.otf");
}

@font-face {
  font-family: "Rebrand Display";
  font-style: normal;
  font-weight: bolder;
  src: url("../assets/fonts/Rebrand/static/RebrandDis-ExBold.otf");
}

@font-face {
  font-family: "Rebrand Display";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/Rebrand/static/RebrandDis-Bold.otf");
}

:root {
  /** primary/brand **/
  --ion-color-primary: #007367;
  --ion-color-primary-rgb: 0, 115, 103;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003a34;
  --ion-color-primary-tint: #99c7c2;

  /** secondary/ inverse of brand **/
  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #007367;
  --ion-color-secondary-contrast-rgb: 255, 192, 58;
  --ion-color-secondary-shade: #99c7c2;
  --ion-color-secondary-tint: #003a34;

  /** tertiary/info **/
  --ion-color-tertiary: #3571dc;
  --ion-color-tertiary-rgb: 53, 113, 220;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 66, 73, 99;
  --ion-color-tertiary-shade: #1b396e;
  --ion-color-tertiary-tint: #aec6f1;

  /** success **/
  --ion-color-success: #23c18f;
  --ion-color-success-rgb: 35, 193, 143;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #126148;
  --ion-color-success-tint: #a7e6d2;

  /** warning **/
  --ion-color-warning: #ffb833;
  --ion-color-warning-rgb: 255, 184, 51;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #805c1a;
  --ion-color-warning-tint: #ffe3ad;

  /** danger **/
  --ion-color-danger: #e71341;
  --ion-color-danger-rgb: 231, 19, 65;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #740a21;
  --ion-color-danger-tint: #f5a1b3;

  /** dark **/
  --ion-color-dark: #1e293b;
  --ion-color-dark-rgb: 30, 41, 59;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #14171c;
  --ion-color-dark-tint: #334155;

  /** medium **/
  --ion-color-medium: #64748b;
  --ion-color-medium-rgb: 100, 116, 139;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #505e75;
  --ion-color-medium-tint: #94a3b8;

  /** light **/
  --ion-color-light: #e2e8f0;
  --ion-color-light-rgb: 226, 232, 240;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cbd5e1;
  --ion-color-light-tint: #f2f4f7;

  /** weaver-component-only **/
  --ion-color-weaver-component: #d000d0;
  --ion-color-weaver-component-rgb: 208, 0, 208;
  --ion-color-weaver-component-contrast: #fff;
  --ion-color-weaver-component-contrast-rgb: 255, 255, 255;
  --ion-color-weaver-component-shade: #680068;
  --ion-color-weaver-component-tint: #ec99ec;

  /** weaver colors **/
  --ion-background-color: #f2f4f7;
  --ion-item-background: #fff;
  --weaver-team-only-color: #d000d0;
  --ion-text-color: #1e293b;
  --ion-text-color-rgb: 30, 41, 59;
  --weaver-new-messages-line-color: #f00;

  /* Weaver global variables */
  --theme-custom-box-shadow: rgb(0 0 0 / 15%) 0.1rem 0.1rem 0.15rem;
  --theme-rounding: 0.5rem;
  --theme-white: white;
  --default-text-area-border: 0.0625rem solid #242424;
  --ion-color-bronze: #cd7f32;
  --ion-color-silver: #e5ebf2;
  --ion-color-gold: #ffd700;
  --ion-color-yellow: #fbde89;

  /** Allows to overwrite header **/
  --common-font-rebrand-text: "Rebrand text";
}

/* stylelint-disable selector-class-pattern */

/* This should ONLY be used for components that are WEAVER TEAM ONLY */
.ion-color-weaver-component {
  --ion-color-base: var(--ion-color-weaver-component);
  --ion-color-base-rgb: var(--ion-color-weaver-component-rgb);
  --ion-color-contrast: var(--ion-color-weaver-component-contrast);
  --ion-color-contrast-rgb: var(--ion-color-weaver-component-contrast-rgb);
  --ion-color-shade: var(--ion-color-weaver-component-shade);
  --ion-color-tint: var(--ion-color-weaver-component-tint);
}

/* global selector: The list item background colour defaults to white, which doesn't always match the theme */
ion-list.list-md,
ion-list.list-ios {
  background-color: transparent;
}
/* stylelint-enable selector-class-pattern */

/*
stylelint-disable-next-line selector-class-pattern --
global selector: The default scrollbar had shaddow and and square corners.
It was styled globally so that it fitted the appearance of GenericSelectorList
*/
ion-searchbar.sc-ion-searchbar-md-h {
  --ion-background-color: #fff;
  --border-radius: 0.3rem;
  --box-shadow: none;

  padding: 0;
}

* {
  font-family: "Rebrand Text", "Trebuchet MS", sans-serif;
  line-height: 1.2em;
}

/* stylelint-disable-next-line selector-max-type */
ion-button {
  text-transform: none;
}

/* stylelint-disable-next-line selector-max-type */
ion-title {
  font-family: "Rebrand Display", "Trebuchet MS", sans-serif;
  line-height: 1.2em;
}

/* stylelint-disable-next-line selector-max-type */
ion-card-title {
  font-family: "Rebrand Display", "Trebuchet MS", sans-serif;
  line-height: 1.2em;
}

/* stylelint-disable selector-class-pattern --
  Disabled to apply color changes on refresher pulling icon  */
.refresher-ios .refresher-pulling-icon,
.refresher-ios .refresher-refreshing-icon {
  color: var(--ion-color-primary);
}
/* stylelint-enable */

ion-accordion::part(header) {
  border-bottom: 0.01rem solid var(--ion-color-light-shade);
}

/* stylelint-disable-next-line selector-class-pattern */
.ion-accordion-toggle-icon {
  font-size: 1.1rem;
  color: var(--ion-color-medium);
  margin: 0;
}

/* stylelint-disable-next-line selector-max-type */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rebrand Display", "Trebuchet MS", sans-serif;
  line-height: 120%;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* stylelint-disable-next-line selector-max-type --
  Disabled to apply max width to all footers for responsive design
  */
  ion-footer {
    max-width: 50%;
    margin: auto;
  }
}

/* @media (prefers-color-scheme: dark) {

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
 */
