.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.25rem;
  margin: 0;
}

.swiperContainer {
  height: 100%;
}

.swiper {
  height: 100%;
}

.ionItemInput {
  --border-width: 0.1em;
  --border-radius: 0.3em;

  padding-top: 0.5rem;
}

.flex {
  display: flex;
}

.slideContainer {
  padding: 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > p {
    text-align: center;
  }

  .errorMessage {
    text-align: end;
    margin: 0 0 0.2rem;
    font-size: 0.8rem;
    color: var(--ion-color-danger);
  }

  .contractorChoices {
    display: flex;
    flex-direction: column;
    min-height: 35%;
    justify-content: space-around;

    ion-button {
      height: 5rem;

      div {
        text-transform: none;
      }

      h2,
      h3 {
        margin: 0;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.submitOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: var(--ion-color-light);
  z-index: 999;
}
