.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkMark {
  color: var(--ion-color-success);
  position: relative;
  right: 1rem;
}

.warning {
  color: var(--ion-color-dark);
  position: relative;
  right: 1rem;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    font-weight: bold;
    color: var(--ion-color-light);
  }
}

.avatarContainer {
  display: flex;
  margin-left: 1rem;
}

.avatar {
  background-color: var(--ion-color-medium-tint);
}

.chatMemberName {
  margin-top: 0.5rem;
  max-width: 5rem;
  height: 2.5rem;
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
