.mainContainer {
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  background-color: var(--ion-color-success-tint);
  display: flex;
  flex-direction: column;
}

.titleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
