@keyframes pulse {
  from {
    transform: translateY(10rem);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 1;
  }

  65% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  80% {
    transform: scale(1);
  }

  95% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in {
  from {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }

  // the reason why this is 50% is because we need to show
  // the button after the end of the pulse animation

  50% {
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.continueBtn {
  animation-name: fade-in;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  height: 3rem;
  margin-top: 3rem;
}

.badge {
  margin-top: 5rem;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInUp {
  animation-name: fade-in-up;
  animation-duration: 1.7s;
  animation-timing-function: ease-in-out;
}
