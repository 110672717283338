.mainContainer {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--ion-background-color);
}

.checkMarkCircleSharpImage {
  margin-top: 3rem;
  height: 5rem;
  width: 5rem;
}

.uploadYourDocumentsText {
  font-weight: bold;
}

.supportedFormatsText {
  color: var(--ion-color-medium);
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.continueToRouterLinkButton {
  text-transform: uppercase;
}
