%thumbnailContainerCommonProperties {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-dark);
  position: relative;
  cursor: pointer;
}

.thumbnailPhotoContainer {
  @extend %thumbnailContainerCommonProperties;

  height: 8rem;
  width: 8rem;
  border-width: 0.025rem;
  border-style: solid;
  border-color: var(--ion-color-dark);

  &.success {
    ion-icon {
      color: var(--ion-color-dark);
    }
  }

  &.error {
    background-color: var(--ion-color-danger);
  }
}

.thumbnailPhotoContainerSmall {
  @extend %thumbnailContainerCommonProperties;

  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2rem;

  > img {
    border-radius: 15%;
  }

  &.success {
    ion-icon {
      color: var(--ion-color-dark);
    }
  }

  &.error {
    background-color: var(--ion-color-danger);
  }
}

.thumbnailPhoto {
  max-height: 8rem;
  padding: 0.05rem;
  max-width: 8rem;
}

.thumbnailPhotoSmall {
  width: 100%;
  height: 100%;
}

.retryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: auto;
}

.topRightIcon {
  position: absolute;
  color: var(--ion-color-dark);
  top: 0;
  right: 0;
}

.spinner {
  font-weight: bold;
  justify-content: center;
  color: var(--ion-color-dark);
  width: 2rem;
  height: 2rem;
  margin-right: 0.125rem;
}
