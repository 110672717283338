.topBackground {
  height: 11vh;
  background-color: var(--ion-color-primary);
  padding: 0 2.8rem;

  svg {
    margin-top: 0.75em;
    height: 9vh;
  }
}

.selectMembershipBody {
  background-color: var(--ion-item-background);

  h3 {
    margin-bottom: 0.5rem;
  }

  .titleClassName {
    font-size: 1.75rem;
    margin-top: 0;
  }

  .paymentButtonsContainer {
    display: flex;
    flex-flow: column wrap;

    .firstButton {
      min-height: 4rem;
      text-transform: none;
      font-weight: 800;
      font-size: 1.125rem;
    }

    .secondButton {
      font-weight: 800;
      min-height: 3rem;
      text-transform: none;
      font-size: 1.125rem;
      margin-bottom: 3rem;
    }
  }

  .bulletPointContainer {
    .tenderReadinessText {
      padding-top: 0;

      .tenderReadinessTextLine {
        line-height: 1.5rem;
      }

      ion-icon {
        color: var(--ion-color-success);
        margin-bottom: -0.13rem;
      }
    }
  }
}
