.ionItem {
  --padding-start: 0.7rem;

  .ionLabel {
    line-height: 1rem;
    font-size: 1rem;

    .ionLabelDescription {
      font-size: 0.75rem;

      span {
        font-size: 0.75rem;
        margin: 0 0.5rem;
      }
    }
  }
}
