.outerContainer {
  --ion-background-color: var(--ion-color-primary);
}

.container {
  max-width: 40rem;
}

.weaverLogo {
  height: 15vh;
  margin: auto;
  margin-top: 2vh;
}

.caption {
  text-align: center;
  color: var(--ion-color-medium-contrast);
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.loginButton {
  margin-top: 2rem;
  width: 70%;
  height: 4rem;
  font-size: 1.5rem;
}

.promoImage {
  height: 50vh;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
}

@media (max-width: 45rem) {
  .caption {
    font-size: 1.7rem;
  }

  .loginButton {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    height: 2.5rem;
  }

  .promoImage {
    max-width: 20rem;
  }
}

@media (max-height: 45rem) {
  .improvePromoImage {
    height: 45vh;
  }
}

@media (max-height: 42.5rem) {
  .improvePromoImage {
    height: 40vh;
  }
}

@media (max-height: 40rem) {
  .improvePromoImage {
    height: 35vh;
  }

  .caption {
    font-size: 1.5rem;
  }

  .loginButton {
    margin-top: 1rem;
  }
}

@media (max-height: 24rem) {
  .improvePromoImage {
    height: 30vh;
  }

  .caption {
    font-size: 1.2rem;
  }

  .loginButton {
    margin-top: 0.7rem;
  }
}
