$tab-nav-bar-height: 3.9rem;

.footerContainer {

  /* stylelint-disable-next-line color-function-notation */
  border-top: 0.034rem solid  rgba(var(--ion-color-light-contrast-rgb), 0.1);
  background: var(--ion-tab-bar-background, var(--ion-color-step-50));
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .ionFooter {
    display: flex;
    align-items: center;
    max-width: 42rem;
    background: var(--ion-background-color);

    .tabsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 2rem;
      margin-right: 2rem;
      width: 100%;

      .section {
        line-height: 1.2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: $tab-nav-bar-height;

        .ionIcon {
          font-size: 1.55rem;
          margin-bottom: 0.125rem;
          margin-top: 0.1rem;

          &.mobile {
            font-size: 1.8rem;
          }

          --color: var(--ion-tab-bar-color, var(--ion-color-step-400, --ion-color-step-600));
        }

        .ionLabel {
          font-size: 0.8rem;

          --color: var(--ion-tab-bar-color, var(--ion-color-step-400, --ion-color-step-600));
        }
      }
    }
  }
}

.footerContainerHidden {
  display: none;
}

.redDot {
  background: var(--ion-color-danger);
  width: 0.7rem;
  position: absolute;
  left: 1.12rem; // This specific margin is specified so the button looks good enough in web and web mobile.
  height: 0.7rem;
  border-radius: 50%;
  z-index: 1;
}

.tabIconContainer {
  position: relative;
}
