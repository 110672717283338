.uploadBubble {
  color: var(--ion-color-dark);
  font-weight: normal;
  font-size: 0.75rem;
  align-self: flex-start;
  min-height: 1.9rem;
  min-width: 5.75rem;
  max-width: 18rem;
  text-transform: lowercase;
  border-radius: 1.5625rem;
  border-width: 0.025rem;
  border-style: solid;
  padding-bottom: 0.25rem;
  border-color: var(--ion-color-dark);
  background-color: var(--ion-background-color);
  text-overflow: ellipsis;

  span {
    overflow: hidden;
    max-width: 14rem;
    text-overflow: ellipsis;
  }

  ion-icon {
    margin-top: 0.25rem;
    margin-left: 0.35rem;
    width: 1rem;
  }

  &.success {
    ion-icon {
      color: var(--ion-color-success-shade);
    }
  }

  &.error {
    background-color: var(--ion-color-danger);

    ion-icon {
      color: var(--ion-color-dark);
    }
  }
}

.uploadingFileStatusSpinner {
  font-weight: bold;
  color: var(--ion-color-dark);
  height: 0.75rem;
  width: 1rem;
  margin-left: 0.35rem;
  margin-top: 0.25rem;
}
