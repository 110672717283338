.ionFooter {
  background-color: var(--ion-background-color);

  .ionToolbar {
    --background: transparent;
  }
}

.photosContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 10em);
  grid-template-rows: auto;

  .photoItemContainer {
    img {
      object-fit: contain;
      height: 100%;
    }
  }
}

.gridRowGap {
  grid-row-gap: 1rem;
}
