.deviceStorageFileListContainer {
  height: 100%;
}

.header {
  margin-left: 1rem;
}

.deviceStorageFileRow {
  color: var(--ion-color-light-contrast);
  font-weight: normal;
  align-items: center;
}

.emptyRecentFilesPlaceholder {
  border-radius: 0.7rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  max-width: 80%;
  min-height: 3.75rem;
  clear: both;
}

.backButton {
  margin: 0;
  padding: 0;
}
