.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}

.imageContainer {
  background-color: var(--ion-color-primary);
}

.phoneImg::part(image) {
  max-height: 35vh;
  object-fit: contain;
}
