.ionAccordionGroup {
  ion-accordion::part(header) {
    border-bottom: none;
    margin-bottom: 0.3rem;
  }

  ion-accordion::part(header expanded) {
    border-bottom: 0.01rem solid var(--ion-color-light-shade);
    margin-bottom: 0;
  }

  ion-accordion::part(header expanded):last-child {
    border-bottom: 1rem solid var(--ion-color-light-shade);
  }
}

.accordionPlaceholder {
  background: var(--ion-item-background);
  padding: var(--ion-padding, 1.5rem);
}

.ionCard {
  margin: 0;
  margin-inline: 0;
  margin-top: 2rem;

  .ionCardContent {
    padding: 0;
  }
}

.ionRefresher {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}
