.chatFooter {
  background-color: var(--ion-item-background);
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
  .chatFooter {
    margin: 0 auto;
    max-width: 50%;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.container {
  display: flex;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  flex-direction: column;
}

.messagingRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;
  justify-content: center;
  overflow: hidden;
}

.messagingRowContainer ion-button {
  margin: 0;
}

.messageTextAreaContainer {
  width: 100%;
  max-height: 8rem;
}

.chatFooter ion-textarea {
  background-color: var(--ion-item-background);
  color: var(--ion-color-light-contrast);
  border-radius: 0.5rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  caret-color: var(--ion-color-primary);
  max-height: 8rem;
  overflow-y: scroll;

  &.hideScrollbar {
    overflow-y: auto;
  }
}

.selectedFilesContainer {
  margin-top: 0.5rem;
}

.uploadSuccededSelectedBubble {
  color: var(--ion-color-light-contrast);
  margin-right: 0.175rem;
  margin-top: 0.175rem;
  font-weight: normal;
  font-size: 0.75rem;
  height: 1.8rem;
  text-transform: lowercase;
  border-radius: 1.5625rem;
  border: var(--default-text-area-border);
}

.uploadSuccededIcon {
  color: var(--ion-color-success-shade);
}

.uploadErroredSelectedBubble {
  color: var(--ion-color-danger);
  margin-right: 0.175rem;
  margin-top: 0.175rem;
  font-weight: normal;
  height: 2rem;
  font-size: 0.75rem;
  text-transform: lowercase;
  border-radius: 1.5625rem;
  border: var(--default-text-area-border);
  border-color: var(--ion-color-danger);
}

.retryButton {
  color: var(--ion-color-danger);
  padding: 0;
  margin: 0;
}

.uploadInProgressSelectedBubble {
  color: var(--ion-color-light-contrast);
  margin-right: 0.175rem;
  margin-top: 0.175rem;
  height: 1.8rem;
  font-weight: normal;
  font-size: 0.75rem;
  text-transform: lowercase;
  border-radius: 1.5625rem;
  border: var(--default-text-area-border);
}

.uploadingFileStatusSpinner {
  font-weight: bold;
  color: var(--ion-color-light-contrast);
  height: 0.75rem;
}
