.budgetFeeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    margin: 0;
    max-width: 60vw;
  }

  ion-title {
    font-size: 3em;
  }

  .largerHeading {
    font-size: 1.5em;
  }
}

.feeListItems {
  width: fit-content;
  margin: 1.5em auto 2.5em;
  padding-left: 0;

  span {
    list-style: none;
    display: flex;
    margin-bottom: 1em;
    align-items: center;

    p {
      margin: 0;
      max-width: 80%;
      font-size: 1.1em;
    }

    ion-icon {
      font-size: 2em;
      color: var(--ion-color-success);
      margin-right: 0.5em;
    }
  }
}

.payButton {
  margin: auto;
}

.paymentFee {
  white-space: nowrap;
  font-size: 3rem;
  font-weight: 800;
}

.paymentVAT {
  font-size: 1rem;
  font-weight: 400;
}

.retryButton {
  margin-top: 1rem;
}
