.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.ionOutlineButton {
  --border-width: 0.08rem;
}

.ionText {
  line-height: 1.7rem;
}
