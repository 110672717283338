.modalContainer {
  padding: 0 1rem 2rem;
  color: var(--ion-text-color);
}

.phoneInput {
  background-color: transparent;

  @media (prefers-color-scheme: dark) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }

  @media (prefers-color-scheme: light) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }
}

.addItem {
  ion-avatar {
    background-color: var(--ion-color-light-shade);
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
      color: var(--ion-color-light-contrast);
      font-size: 1.2rem;
    }
  }
}
