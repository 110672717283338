.ionIcon {
  font-size: 1.3em;
}

.closeIcon {
  font-size: 2em;
}

.ionToolbar {
  --background: var(--ion-color-light-tint);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ionToolbar {
    max-width: 50%;
    margin: auto;
  }
}
