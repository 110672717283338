.currencyInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 0;
  background-color: inherit;
  border: 0;
  padding: 1rem 0;
  outline: none;

  input {
    border: 0;
    outline: none;
    background-color: var(--theme-white);
  }
}
