.textContainer {
  height: 17rem;
}

.title {
  font-size: 1.4em;
  font-weight: 900;
}

.centeredText {
  p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .imageContainer {
    width: 100%;
    background-color: var(--ion-color-primary);

    .projectPhoto {
      height: 17rem;
    }
  }
}
