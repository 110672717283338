.contentContainer {
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.4rem 0 0.8rem;

  .inputContainer {
    border: 0.05rem solid var(--ion-color-medium);
    border-radius: 0.4rem;
  }
}

.label {
  font-size: 1rem;
}

.subtext {
  font-size: 0.7rem;
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}
