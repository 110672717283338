.weaverDatePickerContainer {
  background-color: var(--ion-item-background);
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 0.5rem;
  box-shadow: var(--theme-custom-box-shadow);
  min-height: 100%;
}

.datePickerHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  .datePickerHeadingPrompt {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--ion-color-medium-tint);
    margin: 0;
  }

  .datePickerHeadingLine {
    margin-top: 1rem;
    height: 0.09rem;
    width: 70%;
    background-color: var(--ion-color-light-shade);
  }

  .datePickerHeadingSelector {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    background-color: var(--ion-color-light-tint);
    border-radius: 2rem;

    .datePickerHeadingSelectorButton {
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      cursor: pointer;
      background-color: var(--ion-color-light-tint);
    }

    .datePickerHeadingSelectorButtonActive {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-light);
    }

    .datePickerHeadingSelectorButtonActive:first-child {
      margin-right: 0.5rem;
    }

    .datePickerHeadingSelectorButtonActive:not(:first-child) {
      margin-left: 0.5rem;
    }

    .datePickerHeadingSelectorButtonActive:not(:first-child):not(:last-child) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .delimiter {
    font-weight: 600;
  }
}

.currentDateBlank {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0.5rem;
}

.currentDateActiveYear {
  color: var(--ion-color-secondary);
}

.currentDateActiveMonth {
  color: var(--ion-color-secondary);
}

.currentDateActiveDay {
  color: var(--ion-color-secondary);
}

.currentDateFilled {
  font-size: 1.2rem;
  font-weight: 600;
}

.datePickerGridContainer {
  min-width: 75%;
  padding-bottom: 2.5rem;
}

.datePickerGridRow {
  margin: auto;

  > ion-col {
    display: flex;
    justify-content: center;

    ion-button {
      width: 100%;
      font-weight: 600;
    }
  }
}
