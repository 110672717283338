.indicatorContainer {
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 1.6rem;
  margin-bottom: 1.5rem;

  .indicatorBar {
    width: 100%;
    height: 0.08rem;
    background: var(--ion-color-light-shade);
    flex: 1;
  }

  .indicatorBar[data-status="COMPLETED"] {
    background: var(--ion-color-primary);
  }

  .stepContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 3.125rem;

    .stepName {
      font-size: 0.75rem;
      position: absolute;
      top: 100%;
      font-weight: 600;
      max-width: 4rem;
      white-space: normal;

      :first-child {
        display: inline-block;
      }

      .title {
        font-weight: 700;
        font-size: clamp(0.8rem, 1.5vw, 1rem);
      }
    }

    .stepIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 0.08rem solid var(--ion-color-light-shade);
      background-color: var(--ion-color-secondary);
      transition: background-color 500ms;

      ion-text {
        font-size: 0.9rem;
        color: var(--ion-color-primary);

        p {
          font-weight: bold;
        }
      }
    }

    .stepIconContainer[data-status="IN_PROGRESS"] {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-primary);

      ion-text {
        color: var(--ion-color-secondary);
      }
    }

    .stepIconContainer[data-status="COMPLETED"] {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-primary);

      ion-icon {
        font-size: 1rem;
        color: var(--ion-color-secondary);
      }
    }
  }
}
