.topBackground {
  background-color: var(--ion-color-primary);
  padding: 2.8rem;
}

.ionText {
  line-height: 1.7rem;
}

.centeredText {
  text-align: center;

  p {
    font-size: 0.9rem;
  }
}

// Below classes are temporarily here in order to make it work with storybook.
// They are belonging in the component which hosts the Title of description generic page and
// makes use of a custom class like that.

.locationTopBackground {
  padding-left: 4rem;
  padding-right: 4rem;
}

.customIonImg {
  height: 15rem;
}
