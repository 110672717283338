.detailedContainer {
  width: 100%;
  color: var(--ion-color-primary);
  border: 0.1rem solid var(--ion-color-primary);
  border-radius: 0.2rem;
}

.ellipsisSupport {
  overflow: hidden;
  text-overflow: ellipsis;
}
