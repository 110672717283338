// Required to remove default hover animation thats found in parent button (.sc-ion-buttons-md-s .button)
// Tried to be overridden in variables.css to no avail
// HACK & open to be fixed
.backButton {
  /* stylelint-disable-next-line declaration-no-important */
  --background-hover: none !important;
}

.pageSubtitle {
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .globalHeader {
    max-width: 50%;
    margin: 0 auto;
  }
}
