.modalContainer {
  padding: 0 1rem 2rem;

  &.editableInputs {
    ion-input {
      border: 0.05rem solid var(--ion-color-medium);
      border-radius: 0.4rem;

      /*
        Padding start is by default 0.5rem or 8px on android ion-input,
        however that is not the case for ios, this has a result an ugly ui on ios inputs.
        We're using that extra padding for consistency among the platforms
      */
      --padding-start: 0.5rem;
      --background: var(--ion-item-background);
    }
  }
}

.phoneInput {
  margin-bottom: 1rem;

  @media (prefers-color-scheme: dark) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }

  @media (prefers-color-scheme: light) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }
}

.editablePhoneInput {
  @media (prefers-color-scheme: dark) {
    input {
      height: 2.5rem;
      border: 0.05rem solid var(--ion-color-medium);
      border-radius: 0.4rem;
      background-color: var(--ion-item-background);
    }
  }

  @media (prefers-color-scheme: light) {
    input {
      height: 2.5rem;
      border: 0.05rem solid var(--ion-color-medium);
      border-radius: 0.4rem;
      background-color: var(--ion-item-background);
    }
  }
}
