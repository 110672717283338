.ionItemInput {
  --border-width: 0.1em;
  --border-radius: 0.3em;

  padding-top: 0.5rem;
}

.ionInputLabel {
  margin-bottom: 0.2rem;
}

.ionInput:invalid {
  --background: black;
}

.errorMessage {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}
