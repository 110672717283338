.addConstructionModalContent {
  margin-top: -1rem;
}

.ionToolbar {
  --background: transparent;
}

.constructionOngoingCheckBox {
  --background: none;
}

.errorMessage {
  width: fit-content;
  margin-left: auto;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .addConstructionModal {
    --height: 50rem;
    --width: 60rem;
  }
}
