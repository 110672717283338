.ionBackDrop {
  opacity: 1;
  background-color: var(--ion-color-danger-tint);
}

.customAlert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: var(--ion-background-color);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
  overflow-x: scroll;

  .customAlertContent {
    display: flex;
    flex-flow: column;
    align-items: center;

    ion-icon {
      font-size: 6rem;
      color: var(--ion-text-color);
    }
  }

  &.supportDarkMode {
    height: 24rem;
    width: 20rem;
    overflow: visible;
    overflow-y: auto;

    .title,
    .description {
      color: var(--ion-text-color);
    }

    @media screen and (max-device-width: 20rem) and (orientation: portrait) {
      height: 28rem;
    }
  }
}
