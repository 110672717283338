.topBackground {
  height: 30vh;
  background-color: var(--ion-color-primary);
  padding: 0 2.8rem;

  .headerSubtitle {
    color: var(--theme-white);
    font-size: 1.5em;
  }

  svg {
    margin-top: 2em;
    height: 15vh;
  }
}

.selectMembershipBody {
  h3 {
    margin-bottom: 1.5em;
  }

  ion-item {
    max-width: 80vw;
    margin: auto;
    margin-bottom: 1em;
    border-radius: var(--theme-rounding);

    h5 {
      margin-bottom: 0.1em;
    }

    p {
      margin-top: 0;
      color: var(--ion-color-medium);
    }
  }
}
