.chatPage {
  .chatPage ion-header,
  .chatPage ion-toolbar {
    --min-height: 3.5rem;
  }

  .chatPage ion-title {
    margin-left: -3.5rem;
  }

  .chatPage ion-title p {
    padding: 0;
    margin: 0;
  }

  .chatBubbleParentSent {
    display: flex;
    justify-content: flex-end;
  }

  .chatBubbleParentReceived {
    display: flex;
    justify-content: flex-start;
  }

  .chatBubble {
    border-radius: 0.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.5rem;
    max-width: 80%;
    min-height: 3.75rem;
    clear: both;
    box-shadow: var(--theme-custom-box-shadow);
  }

  .chatMessageWithDocumentsBubble {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.5rem;
    max-width: 80%;
    min-height: 3.75rem;
    clear: both;
    box-shadow: var(--theme-custom-box-shadow);
  }

  .chatBubble:last-child,
  .chatMessageWithDocumentsBubble:last-child {
    margin-bottom: 0.8rem;
  }

  .chatBubble:first-child,
  .chatMessageWithDocumentsBubble:first-child {
    margin-top: 1.8rem;
  }

  .bubbleSent {
    background-color: var(--ion-item-background);
  }

  .bubbleReceived {
    background-color: var(--ion-item-background);
  }

  .chatBottomDetails {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.4rem;
  }

  .chatBottomDetails span {
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }

  .chatBottomSenderName {
    font-style: italic;
  }

  .chatDocumentItem {
    background-color: var(--ion-item-background);
    border: 1px solid var(--ion-color-medium);
    border-radius: 0.8rem;
    width: 14rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .chatMessageDocumentName {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 8rem;
    text-overflow: ellipsis;
    font-weight: 600;
  }

  .chatMessageDocumentSize {
    font-size: 0.8rem;
    color: var(--ion-color-medium);
  }

  .downloadIcon {
    font-size: 1.3rem;
    margin-left: 0.2rem;
    margin-right: 0.5rem;
  }

  .downloadSpinner {
    font-size: 1.3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    max-width: 1rem;
    max-height: 1.3rem;
  }
}

.thumbnailPhotoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-dark);
  border-bottom-width: 0.025rem;
  border-bottom-style: solid;
  height: 7rem;
  width: 100%;
  cursor: pointer;
  border-color: var(--ion-color-dark);

  &.success {
    ion-icon {
      color: var(--ion-color-dark);
    }
  }
}

.chatMessageDocumentContainer {
  background-color: var(--ion-item-background);
  border: 1px solid var(--ion-color-medium);
  margin-bottom: 0.5rem;
  display: flex;
  height: 10rem;
  max-width: 12rem;
  flex-direction: column;
}

.chatMessageImage {
  height: 7rem;
  width: 100%;
  cursor: pointer;
}

.chatMessageImageBottomContainer {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.chatMessageImageDetailsContainer {
  justify-content: space-evenly;
  display: flex;
  margin-left: 0.5rem;
  flex-direction: column;
}

.chatMessageImageDownloadContainer {
  display: flex;
  width: 4rem;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.newMessagesLine {
  width: 100%;
  height: 0.0625rem;
  margin-right: 1rem;
  background: var(--weaver-new-messages-line-color);
}

.newMessagesContainer {
  display: flex;
  align-items: center;
  color: var(--weaver-new-messages-line-color);
  height: 1rem;
  width: 100%;
  padding-right: 0.85rem; // Margin needed especially when there is a scroll bar.
  padding-left: 0.85rem;
}

.chatEmptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background-color: var(--ion-color-primary-contrast);
  width: 100%;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  margin-top: 1rem;
}
