.mainContainer {
  width: 100%;
}

.currentPrice {
  font-weight: bold;
  color: var(--ion-color-danger);
  margin-left: 0.5rem;
}

.originalPrice {
  margin-left: 1rem;
}

.lineThrough {
  text-decoration: line-through;
}
