.next {
  --ion-item-background: var(--ion-color-warning-tint);

  border-color: var(--ion-color-warning);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--ion-color-warning);
    color: var(--ion-text-color);
  }
}

.waiting {
  --ion-item-background: var(--ion-color-tertiary-tint);

  border-color: var(--ion-color-tertiary);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--ion-color-tertiary-tint);
    color: var(--ion-text-color);
  }
}

.toDo {
  ion-badge {
    background-color: var(--ion-color-medium);
    color: var(--ion-text-color);
  }
}

.done {
  --ion-item-background: var(--ion-color-success-tint);

  border-color: var(--ion-color-success);
  border-style: solid;
  border-width: 0.185em;

  ion-badge {
    background-color: var(--ion-color-success);
    color: var(--ion-text-color);
  }
}
